gridster {
  background: transparent !important;
}

.k-toolbar {
  border: none;
}

kendo-notification .k-notification {
  padding: 10px 12px;

  .k-notification-wrap {
    align-items: center;

    span:first-child {
      font-size: 32px;
      margin-top: 0;
      margin-right: 10px;
    }

    .k-notification-content {
      max-width: calc(100% - 66px);
    }

    a:last-child {
      margin-bottom: auto;
    }
  }
}

.k-notification-group {
  z-index: 1;
}

.k-dialog {
  width: 90vw;
  height: 90vh;
  max-width: 800px;
  max-height: 800px;

  kendo-dialog-actions {
    justify-content: flex-end;
  }
}

.dashboard__item--tooltip {
  ul {
    padding-left: 25px;
    margin: 5px 0;
  }
}

.k-dialog {
  .k-window-content {
    padding: 0px 24px;
  }
}

.staff-list {
  .k-expander {
    margin-top: 0 !important;
    box-shadow: none !important;

    .k-expander-header {
      padding: 10px;

      .header-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }

    .k-expander-content {
      padding: 0 !important;
    }
  }
}

.k-columnmenu-actions {
  > button {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
