/* Theme color that differs from material theme */
@import './styles/fonts.scss';
@import './styles/icons.scss';
@import './styles/components.scss';
@import 'theme/dist/scss';

:root {
  --kendo-font-family: Roboto, Arial, sans-serif;
  --logo-image: url('./assets/images/logo.png');
  --accent-color: rgba(193, 13, 127, 1);
}

body {
  margin: 0;
  box-sizing: border-box;
  font-family: var(--kendo-font-family);
  background: url('./assets/images/background.jpg') no-repeat center center
    fixed;
  background-size: cover;
}
